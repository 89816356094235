import A from "components/A";
import SubA from "components/A/SubA";
import SubB from "components/A/SubB";
import B from "components/B";
import C from "components/C";
import D from "components/D";
import E from "components/E";
import F from "components/F";
import G from "components/G";

export const routes = [
    {
        path: '/b',
        element: <B />
    },
    {
        path: '/c',
        element: <C />
    },
    {
        path: '/d',
        element: <D />
    },
    {
        path: '/e',
        element: <E />
    },
    {
        path: '/f',
        element: <F />
    },
    {
        path: '/g',
        element: <G />
    },
    {
        path: '/',
        element: <A />,
        children: [
            {
                path: 'sub',
                element: <SubA />
            },
            {
                path: 'subB',
                element: <SubB />
            },
            {
                path: '/',
                element: <SubA />
            }
        ]
    }
]