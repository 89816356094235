const { createSlice } = require("@reduxjs/toolkit");


const sidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        tabOpenId: 1,
        subtabOpenId: 1
    },
    reducers: {
        setTabById(state, action) {
            state.tabOpenId = action.payload
        },
        setSubtabById(state, action) {
            state.subtabOpenId = action.payload
        }
    }
})

export const { setTabById, setSubtabById } = sidebarSlice.actions
export default sidebarSlice