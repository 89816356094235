import Chart from 'chart.js/auto'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export default function A() {

    useEffect(() => {
        const barEle = document.getElementById('testbar')
        const pieEle = document.getElementById('testpie')

        const mychart = new Chart(barEle, {
            type: 'bar',
            data: {
                labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                datasets: [{
                    label: '# of Votes',
                    data: [12, 19, 3, 5, 2, 3],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        })

        const DATA_COUNT = 5;
        const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

        const data = {
            labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
            datasets: [
                {
                    label: 'Dataset 1',
                    data: [78, 99, 30, 40, 77],
                    backgroundColor: Object.values([
                        '#4dc9f6',
                        '#f67019',
                        '#f53794',
                        '#537bc4',
                        '#acc236',
                        '#166a8f',
                        '#00a950',
                        '#58595b',
                        '#8549ba'
                    ]),
                }
            ]
        };


        const mychart2 = new Chart(pieEle, {
            type: 'pie',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'Chart.js Pie Chart'
                    }
                }
            },
        })



        return () => {
            mychart.destroy()
            mychart2.destroy()
        }
    }, [])

    return (
        <div>
            出入口閘機鏡頭直播


            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-md-3">
                        <canvas id="testpie"></canvas>
                    </div>
                    <div className="col-md-6">
                        charjs
                        <canvas id="testbar" ></canvas>
                    </div>
                </div>
            </div>

            <div style={{ border: "1px solid gray" }}>

                <Outlet />
            </div>

        </div>
    )
}
