const { createSlice } = require("@reduxjs/toolkit");


const globalSlice = createSlice({
    name: "global",
    initialState: {
        toggleSidebar: false
    },
    reducers: {
        toggleSidebar(state, action) {
            state.toggleSidebar = !state.toggleSidebar
        }
    }
})

export const { toggleSidebar } = globalSlice.actions
export default globalSlice