import { useRoutes } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import { routes } from 'config/route';
import './App.css';


function App() {

  const router = useRoutes(routes)
  console.log(router)

  return (
    <div className="container-fluid wrapper">
      <Header />
      <Sidebar />

      <div className='content-wrapper px-4 py-2' >
        {router}
      </div>

      {/* <div className='main-footer'>
        footer
      </div> */}
    </div>
  );
}

export default App;
