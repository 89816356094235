import sidebarSlice from "config/slice/sidebar";
const { configureStore } = require("@reduxjs/toolkit");
const { default: globalSlice } = require("config/slice/global");


const store = configureStore({
    reducer: {
        global: globalSlice.reducer,
        sidebar: sidebarSlice.reducer
    }
})

export default store