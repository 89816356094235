export const sidebarData = [
    {
        id: 1,
        to: '/',
        linkClass: "nav-link",
        activeClass: "active",
        text: "出入口閘機鏡頭直播",
        iconBeforeText: "nav-icon fas fa-tachometer-alt",
        iconAfterText: "right fas fa-angle-left",
        iconText: "",
        subItems: [
            {
                id: 1,
                to: 'subA',
                linkClass: "nav-link",
                activeClass: "active",
                text: "總收入",
                iconBeforeText: "far fa-circle nav-icon",
                iconAfterText: "",
                iconText: "",
            },
            {
                id: 2,
                to: 'subB',
                linkClass: "nav-link",
                activeClass: "active",
                text: "實時停泊空置比例",
                iconBeforeText: "far fa-circle nav-icon",
                iconAfterText: "",
                iconText: "",
            },
            {
                id: 3,
                to: '#',
                linkClass: "nav-link",
                activeClass: "active",
                text: "時段空置率",
                iconBeforeText: "far fa-circle nav-icon",
                iconAfterText: "",
                iconText: "",
            },
            {
                id: 4,
                to: '#',
                linkClass: "nav-link",
                activeClass: "active",
                text: "實時租務類型",
                iconBeforeText: "far fa-circle nav-icon",
                iconAfterText: "",
                iconText: "",
            },
            {
                id: 5,
                to: '#',
                linkClass: "nav-link",
                activeClass: "active",
                text: "停車場剩餘車位數量列表",
                iconBeforeText: "far fa-circle nav-icon",
                iconAfterText: "",
                iconText: "",
            }
        ]
    },
    {
        id: 2,
        to: '/b',
        linkClass: "nav-link",
        activeClass: "active",
        text: "停車場顯示屏介面",
        iconBeforeText: "nav-icon fas fa-th",
        iconAfterText: "right badge badge-danger",
        iconText: "New",
        subItems: []
    },
    {
        id: 3,
        to: '/c',
        linkClass: "nav-link",
        activeClass: "active",
        text: "會計功能",
        iconBeforeText: "nav-icon fas fa-th",
        iconAfterText: "right badge badge-danger",
        iconText: "New",
        subItems: []
    },
    {
        id: 4,
        to: '/d',
        linkClass: "nav-link",
        activeClass: "active",
        text: "會員管理系統",
        iconBeforeText: "nav-icon fas fa-th",
        iconAfterText: "right badge badge-danger",
        iconText: "New",
        subItems: []
    },
    {
        id: 5,
        to: '/e',
        linkClass: "nav-link",
        activeClass: "active",
        text: "停車場收費模式設置",
        iconBeforeText: "nav-icon fas fa-th",
        iconAfterText: "right badge badge-danger",
        iconText: "New",
        subItems: []
    },
    {
        id: 6,
        to: '/f',
        linkClass: "nav-link",
        activeClass: "active",
        text: "個別停車場管理介面",
        iconBeforeText: "nav-icon fas fa-th",
        iconAfterText: "right badge badge-danger",
        iconText: "New",
        subItems: []
    },
    {
        id: 7,
        to: '/g',
        linkClass: "nav-link",
        activeClass: "active",
        text: "其他功能",
        iconBeforeText: "nav-icon fas fa-th",
        iconAfterText: "right badge badge-danger",
        iconText: "New",
        subItems: []
    },
]