import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sidebarData } from 'config/dataToRender/sidebar'
import { setSubtabById, setTabById } from 'config/slice/sidebar'
import "./index.css"
import { isEqual } from 'lodash'
import { Link } from 'react-router-dom'

const defaultData = sidebarData

export default function Sidebar() {

    const { global, sidebar } = useSelector(state => state)
    const dispatch = useDispatch()
    const { toggleSidebar } = global
    const { tabOpenId, subtabOpenId } = sidebar

    const tabSelectedHandler = (tabId) => () => dispatch(setTabById(tabId))
    const subtabSelectedHandler = (subtabId) => () => dispatch(setSubtabById(subtabId))


    useEffect(() => {
        document.body.classList.add('sidebar-mini', "sidebar-collapse")
        if (isEqual(toggleSidebar, false)) document.body.classList.remove("sidebar-mini", "sidebar-collapse")

    }, [toggleSidebar])

    return (
        <>
            {/* <!-- Main Sidebar Container --> */}
            <aside class="cms-sidebar main-sidebar sidebar-dark-primary elevation-4">
                {/* <!-- Brand Logo --> */}
                <a href="index3.html" class="brand-link" style={{ border: "1px solid red" }}>
                    <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
                        style={{ opacity: 0.8, maxHeight: 22 }} />
                    <span class="brand-text font-weight-light">AdminLTE 3</span>
                </a>

                {/* <!-- Sidebar --> */}
                <div class="sidebar">
                    {/* <!-- Sidebar user panel (optional) --> */}
                    <div class="user-panel mt-3 pb-3 mb-3 d-flex" style={{ border: "1px solid red" }}>
                        <div class="image">
                            <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div class="info">
                            <a href="#" class="d-block">Alexander Pierce</a>
                        </div>
                    </div>

                    {/* <!-- Sidebar Menu --> */}
                    <nav class="mt-2">
                        <ul class="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu">
                            {/* <!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library --> */}
                            {
                                defaultData.map(
                                    item => (
                                        <li key={item.id} className={isEqual(tabOpenId, item.id) ? "nav-item menu-is-opening menu-open" : "nav-item"} onClick={tabSelectedHandler(item.id)}>
                                            <Link to={item.to} className={isEqual(tabOpenId, item.id) ? (item.linkClass + " " + item.activeClass) : item.linkClass}>
                                                <i className={item.iconBeforeText}></i>
                                                <p>
                                                    {item.text}
                                                    <i className={item.iconAfterText}></i>
                                                </p>
                                            </Link>
                                            <ul className="nav nav-treeview">
                                                {
                                                    item.subItems.map(
                                                        subitem => (
                                                            <li key={subitem.id} className="nav-item" onClick={subtabSelectedHandler(subitem.id)}>
                                                                <Link to={subitem.to} className={isEqual(subtabOpenId, subitem.id) ? subitem.linkClass + " " + subitem.activeClass : subitem.linkClass}>
                                                                    <i className={subitem.iconBeforeText} ></i>
                                                                    <p>{subitem.text}</p>
                                                                </Link>
                                                            </li>
                                                        )
                                                    )
                                                }
                                                {/* <li className="nav-item">
                                                    <a href="#" className="nav-link active">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Active Page</p>
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Inactive Page</p>
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </li>
                                    )
                                )
                            }
                            {/* <li class="nav-item menu-open">
                                <a href="#" class="nav-link active">
                                    <i class="nav-icon fas fa-tachometer-alt"></i>
                                    <p>
                                        Starter Pages
                                        <i class="right fas fa-angle-left"></i>
                                    </p>
                                </a>
                                <ul class="nav nav-treeview">
                                    <li class="nav-item">
                                        <a href="#" class="nav-link active">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Active Page</p>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            <i class="far fa-circle nav-icon"></i>
                                            <p>Inactive Page</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    <i class="nav-icon fas fa-th"></i>
                                    <p>
                                        Simple Link
                                        <span class="right badge badge-danger">New</span>
                                    </p>
                                </a>
                            </li> */}
                        </ul>
                    </nav>
                    {/* <!-- /.sidebar-menu --> */}
                </div>
                {/* <!-- /.sidebar --> */}
            </aside>
        </>
    )
}
