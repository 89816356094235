import { Chart } from 'chart.js'
import React, { useEffect } from 'react'

export default function SubA() {

    useEffect(() => {
        const mychart1 = new Chart(document.getElementById('testScale'), {
            type: 'line',
            data: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July'
                ],
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [10, 30, 39, 20, 25, 34, -10],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgb(255, 99, 132)',
                    },
                    {
                        label: 'Dataset 2',
                        data: [18, 33, 22, 19, 11, 39, 30],
                        borderColor: 'rgb(54, 162, 235)',
                        backgroundColor: 'rgb(54, 162, 235)',
                    }
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: 'Suggested Min and Max Settings'
                    }
                },
                scales: {
                    y: {
                        // the data minimum used for determining the ticks is Math.min(dataMin, suggestedMin)
                        suggestedMin: 30,

                        // the data maximum used for determining the ticks is Math.max(dataMax, suggestedMax)
                        suggestedMax: 50,
                    }
                }
            },
        })

        return () => {
            mychart1.destroy()
        }
    }, [])

    return (
        <div className='container-fluid' >
            總收入
            <div className='col-sm-9'>
                <canvas id='testScale'></canvas>
            </div>
        </div>
    )
}
